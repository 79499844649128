<template>
  <section id="intro" class="bg-white">
    <div class="dark:bg-blue-gray-800 dark:bg-opacity-95">
      <div class="container relative flex flex-col items-start pt-16">
        <div class="text-left">
          <h2 class="text-3xl sm:text-4xl text-cool-gray-600 dark:text-cool-gray-50 mb-2 font-semibold">Project Portfolio</h2>
          <p class="sm:text-xl text-cool-gray-400 leading-relaxed">Overzicht met de meest recente projecten</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProjectsIntro',
};
</script>

<style scoped></style>